<template>
  <section class="plr-sm">
    <m-header title="支部列表">
      <el-button type="primary" size="small" @click="$router.push({ path: '/partylist/addBranch' })">新增支部</el-button>
    </m-header>
    <!-- table列表 -->
    <!-- 表格组件 -->
    <el-card>
    <el-table border :data="tableData">
<!--      <el-table-column prop="name" label="支部名称" width="80" align="center"></el-table-column>-->

      <el-table-column prop="name" align="center" label="支部名称">
        <template slot-scope="scope">
          <div class="flex_center">
            {{ scope.row.name}}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div>
            <el-button type="text" @click="prevShowEditModal(scope.row)">编辑</el-button>
            <el-button
                type="text"
                @click="deleteItem(scope.row)"
                style="color: #f0232d"
            >删除</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="tableData.length > 0" class="flex_end p-sm">
      <el-pagination
      @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
          :current-page.sync="search.page"
          :page-size="search.pageSize"
          layout="total,sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>


  </el-card>
  </section>
</template>

<script>
import PartyBranch, {createPartyBranch, deletePartyBranch} from "@/axios/api/partyBranch.js";

export default {
  name: "rate-list",
  data() {
    return {
      search: {
        type: "",
        status: "",
        page: 1,
        pageSize: 10,
      },
      showEditModal: false,
      year: "年份",
      keyword: "关键字",
      province: "省份",
      city: "城市",
      objItem: {},
      radio: 1,
      objList: [],
      classifyList: [],
      name: "",
      loading: false,
      scene: 0,
      tableData: [],
      total: 0
    };
  },
  created() {
    this.getPartyList()
  },
  methods: {
    async getPartyList() {
      //解构出参数
      // const { page, limit } = this;
      // //获取品牌列表的接口
      //当你向服务器发请求的时候，这个函数需要带参数，因此老师在data当中初始化两个字段，代表给服务器传递参数
      let result = await PartyBranch.getPartyBranchInfo(this.search);
      if(result.data.status == 1){
        this.tableData = result.data.data || []
        this.total = result.data.extra.count
      }
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.getPartyList()
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.getPartyList()
    },
    /** 删除列表项 */
    deleteItem(row) {
      let that = this
      this.$Modal.confirm({
        title: '提示',
        content: '是否删除，请确认',
        onOk: async() => {
          let res = await PartyBranch.deletePartyBranch(row.id)
          if (res.data.status == 1) {
            that.getPartyList()
          }
        }
      });
    },

    // 打开编辑
    prevShowEditModal(row) {
      this.$router.push({path: './addBranch', query: {
          id: row.id
        }})
    },

    /** 新建党支部 */
    createPartyBranch(data) {
      let that = this
      this.$Modal.confirm({
        title: '提示',
        content: '确认保存',
        onOk: () => {
          PartyBranch.createPartyBranch(data)
        }
      });
    },
  }
}
</script>

<style scoped>
.flex_end {
 display: flex;
 justify-content: flex-end;
}
</style>