import Axios from '../axios.js';

/** 获取党支部列表信息*/
export const getPartyBranchInfo = (params) =>{
    return Axios({
        url: `/api/admin/partyBranch`,
        method: 'get',
        params
    }).then(
        res => Promise.resolve(res),
        err => Promise.reject(err)
    );
}

/** 删除党支部 */
export const deletePartyBranch = (id) =>{
    return Axios({
        url: `/api/admin/partyBranch/`+ id,
        method: 'delete',
    }).then(
        res => Promise.resolve(res),
        err => Promise.reject(err)
    );
}

/** 新建党支部 */
export const createPartyBranch = (data) =>{
    return Axios({
        url: `/api/admin/partyBranch`,
        method: 'post',
        data
    }).then(
        res => Promise.resolve(res),
        err => Promise.reject(err)
    );
}

/** 修改党支部信息 */
export const updatePartyBranch = (params) =>{
    return Axios({
        url: `/api/admin/partyBranch/${params.id}`,
        method: 'put',
        data: params
    }).then(
        res => Promise.resolve(res),
        err => Promise.reject(err)
    );
}

/** 获取党支部信息 */
export const getBranchDetail = (params) =>{
    return Axios({
        url: `/api/admin/partyBranch/${params.id}`,
        method: 'get',
    }).then(
        res => Promise.resolve(res),
        err => Promise.reject(err)
    );
}

// 在下面写就包含对象引入，以上属于可以解构的
export default {
    getPartyBranchInfo,
    deletePartyBranch,
    createPartyBranch,
    getBranchDetail,
    updatePartyBranch
}